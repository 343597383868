import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getUserLocalStorage } from '../context/AuthProvider/util';

export const Api = axios.create({
    baseURL: "https://backend.painelsaude.info/painel/v1/"
});

Api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const user = getUserLocalStorage();
        config.headers = {
            Authorization: `Bearer ${user?.token}`
        };
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);
