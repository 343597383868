import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Input, Spinner } from 'reactstrap';

import { Footer } from '../components/Footer';
import { getUserLocalStorage } from '../context/AuthProvider/util';

import { formataNumero, formataPorcentagemIbge, somaIndicador } from '../utils';

import masculino from '../assets/images/masculino.svg';
import feminino from '../assets/images/feminino.svg';

import homem from '../assets/images/homem.svg';
import mulher from '../assets/images/mulher.svg';

import diabetes from '../assets/images/diabetes.svg';
import hipertensao from '../assets/images/hipertensao.svg';
import gestantes from '../assets/images/gestantes.svg';

import Condicao from "../charts/Condicao";
import Ibge from "../charts/Ibge";
import Piramide from "../charts/Piramide";
import Zonas from "../charts/Zonas";

import '../styles/painel.scss';
import { Api } from '../services/api';
import { Header } from '../components/Header';

type PainelParams = {
    id: string;
}
type Indicator = {
    rural: number;
    urbano: number;
}

interface IPainel {
    ibgePopulation: number;
    ageGroups: {
        feminino: [],
        masculino: []
    },
    gender: {
        feminino: number;
        masculino: number;
    }
    indicators: {
        diabetes: Indicator;
        gestantes: Indicator;
        hipertensao: Indicator;
    },
    locationArea: {
        rural: number;
        urbano: number;
    },
    total: number;
}

type ResponseData = {
    data: IPainel;
}

type Lista = {
    co_dim_unidade_saude_1: number;
    no_unidade_saude: string;
    nu_cnes: number;
}

type ResponseDataListUbs = {
    data: Lista[];
}

export function Painel() {
    let navigate = useNavigate();
    const user = getUserLocalStorage();
    const [loading, setLoading] = useState(false);
    const { id } = useParams<PainelParams>();
    const [dadosPainel, setDadosPainel] = useState<IPainel>();
    const [listaUbs, setListaUbs] = useState<Lista[]>([]);
    const [nomeUbs, setNomeUbs] = useState("");

    useEffect(() => {
        const getNameUbs = async () => {
            try {
                const response = await Api.get<ResponseData>('get-units');
                const { data } = response.data;
                const ubs = Object.values(data).find((item: any) => item.nu_cnes === id);
                setNomeUbs(ubs.no_unidade_saude);
            } catch (error) {
                alert("Ocorreu um erro ao buscar os items");
            }
        }
        if (id) {
            getNameUbs();
        }
    }, [id]);

    useEffect(() => {
        const getDados = async () => {
            let rota = id ? `get-demographic-info/${id}` : 'get-demographic-info';
            setLoading(true);
            setListaUbs([]);

            try {
                const response = await Api.get<ResponseData>(rota);
                const { data } = response.data;
                //console.log(data);

                if (!id) {
                    const responseListUbs = await Api.get<ResponseDataListUbs>('get-units');
                    const { data: dataList } = responseListUbs.data;
                    setListaUbs(dataList);
                }

                setDadosPainel(data);
            } catch (error) {
                alert("Ocorreu um erro ao buscar os items");
            }
            setLoading(false);
        }

        getDados();
    }, [id]);

    function handleToPainelMunicipio() {
        navigate('/painelx');
    }

    const onChangeSelection = (e: any) => {
        navigate(`/painel/${e.target.value}`);
    };

    return (
        <div id="page-painel">
            <Header />

            {loading ? (
                <div className="contentWrapperLoading">
                    <Spinner color="#361949">Carregando...</Spinner>
                </div>
            ) : (
                <div className="contentWrapper">

                    <hr className="linha my-4" />

                    <h2>{id ? nomeUbs : user.municipio + ' - ' + user.uf}</h2>

                    <div className="container container-cards-principal">
                        <div className="row align-items-start">
                            <div className="col-xl-4">
                                <div className="container-card d-flex flex-column flex-md-row align-items-center justify-content-center my-2 py-2">

                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <h4 className="text-center">População Cadastrada</h4>
                                        <span>{formataNumero(dadosPainel?.total)}</span>
                                    </div>

                                    {!id &&
                                        <div className="d-flex align-items-center">
                                            <Ibge data={[
                                                { value: dadosPainel?.ibgePopulation },
                                                { value: dadosPainel?.total }
                                            ]} />
                                            <div>
                                                <h2>{formataPorcentagemIbge(dadosPainel?.ibgePopulation, dadosPainel?.total)}</h2>
                                                <p>* da população estimada (IBGE)</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="container-card-alt d-flex flex-column flex-md-row align-items-center justify-content-center my-2">
                                    <div className="me-2">
                                        <Zonas data={dadosPainel?.locationArea} />
                                    </div>

                                    <div className="container-dados-zona">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="box-container-light me-2"></div>
                                            <h4>Zona Urbana</h4>
                                        </div>
                                        <span>{formataNumero(dadosPainel?.locationArea.urbano)}</span>
                                    </div>

                                    <div className="container-dados-zona">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="box-container-dark me-2"></div>
                                            <h4>Zona Rural</h4>
                                        </div>
                                        <span>{formataNumero(dadosPainel?.locationArea.rural)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="container-card d-flex align-items-center justify-content-center my-2 py-1">
                                    <div className="d-flex flex-column align-items-center ms-2 me-4">
                                        <img src={homem} alt="Homem" />
                                        <span>
                                            {formataNumero(dadosPainel?.gender.masculino)}
                                        </span>
                                    </div>

                                    <div className="d-flex flex-column align-items-center ms-4 me-2">
                                        <img src={mulher} alt="Mulher" />
                                        <span>
                                            {formataNumero(dadosPainel?.gender.feminino)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="my-5">Proporção de indivíduos cadastrados segundo sexo e idade</h3>

                    <div className='graficoPiramide'>
                        {dadosPainel?.ageGroups ? (
                            <>
                                <div className='w-100 painel-demografico'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='mx-2'>
                                            <img src={masculino} className="img-fluid" alt="Masculino" />
                                        </div>
                                        <div className='mx-2'>
                                            <img src={feminino} className="img-fluid" alt="Feminino" />
                                        </div>
                                    </div>
                                    <Piramide data={dadosPainel?.ageGroups} />
                                </div>

                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <div className="d-flex align-items-center mx-3">
                                        <div className="box-container-light me-2"></div>
                                        <h5 className='mb-0'>Área Urbana</h5>
                                    </div>

                                    <div className="d-flex align-items-center mx-3">
                                        <div className="box-container-dark me-2"></div>
                                        <h5 className='mb-0'>Área Rural</h5>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <h6 className='text-danger'>Sem dados de proporção de indivíduos cadastrados.</h6>
                        )}
                    </div>

                    <h3 className="my-5">Condição de saúde dos indivíduos cadastrados no município</h3>

                    <div className="container">
                        <div className="row container-cards-condicoes">
                            <div className="card-condicao p-2">
                                <span className="nome-condicao">Diabetes</span>
                                <h4>{somaIndicador(dadosPainel?.indicators.diabetes)}</h4>

                                <div className="d-flex align-items-center">
                                    <img src={diabetes} alt="Diabetes" className="mx-2" />
                                    <Condicao data={dadosPainel?.indicators.diabetes} />
                                </div>
                            </div>

                            <div className="card-condicao p-2">
                                <span className="nome-condicao">Hipertensão</span>

                                <h4>{somaIndicador(dadosPainel?.indicators.hipertensao)}</h4>

                                <div className="d-flex align-items-center">
                                    <img src={hipertensao} alt="Hipertensão" className="mx-2" />
                                    <Condicao data={dadosPainel?.indicators.hipertensao} />
                                </div>
                            </div>

                            <div className="card-condicao p-2">
                                <span className="nome-condicao">Gestantes</span>
                                <h4>{somaIndicador(dadosPainel?.indicators.gestantes)}</h4>

                                <div className="d-flex align-items-center">
                                    <img src={gestantes} alt="Gestantes" className="mx-2" />
                                    <Condicao data={dadosPainel?.indicators.gestantes} />
                                </div>
                            </div>

                        </div>

                        <div className="d-flex my-5 justify-content-center">
                            <div className="container-areas d-flex align-items-center me-4">
                                <div className="box-container-light me-2"></div>
                                <h4>Área Urbana</h4>
                            </div>

                            <div className="container-areas d-flex align-items-center ms-4">
                                <div className="box-container-dark me-2"></div>
                                <h4>Área Rural</h4>
                            </div>
                        </div>
                    </div>

                    <div className='my-5'>
                        {id ? (
                            <button
                                type="button"
                                onClick={handleToPainelMunicipio}
                                className="btn btn-primary">
                                Visualizar dados do painel do Município
                            </button>
                        ) : (
                            < Input
                                id="comboUbs"
                                name="comboUbs"
                                type="select"
                                className="combo-ubs"
                                onChange={onChangeSelection}>
                                <option value="" hidden>Visualizar Painel por UBS/PSF</option>
                                {listaUbs.map(ubs => {
                                    return (
                                        <option key={ubs.co_dim_unidade_saude_1} value={ubs.nu_cnes}>
                                            {ubs.no_unidade_saude}
                                        </option>
                                    )
                                })}
                            </Input>
                        )}
                    </div>
                </div>
            )}

            <Footer />
        </div >
    )
}