export function formataNumero(numero: number | undefined) {
    if (numero) {
        return (numero).toLocaleString('pt-BR');
    }

    return "-";
}

export function formataPorcentagemIbge(ibge: number | undefined, populacaoCadastrada: number | undefined) {
    if (ibge && populacaoCadastrada) {
        let totalIbge = (ibge / populacaoCadastrada) * 100;
        return totalIbge.toFixed(2) + "%";
    }

    return "-";
}

export function somaIndicador(indicador: any) {
    let result = 0;

    if (indicador) {
        result = indicador.rural + indicador.urbano;
    }

    return formataNumero(result);
}

export function porcentagemIndicador(indicador: any) {
    let total = 0;

    if (indicador) {
        total = indicador.rural + indicador.urbano;

        //console.log('rural: ', indicador.rural);
        //console.log('urbano: ',  indicador.urbano);
        //console.log('TOTAL: ', total);

        let porcentagemRural = indicador.rural / total * 100;
        let porcentagemUrbano = indicador.urbano / total * 100;

        console.log('porcentagem rural: ', porcentagemRural.toFixed());
        console.log('porcentagem urbana: ', porcentagemUrbano.toFixed());
    }

    return formataNumero(total);
}

export function getFirstName(fullName: string | undefined) {
    if (!fullName)
        return '-';

    const [first] = fullName.toLocaleLowerCase().split(' ');
    const name = first.charAt(0).toUpperCase() + first.slice(1);
    return name;
}

export function formataNome(nome: string | undefined) {
    if (!nome)
        return '-';
    let nomeTratado = nome.toLowerCase().replace(/(?:^|\s)\S/g, function (capitalize) { return capitalize.toUpperCase(); });

    let PreposM = ["Da", "Do", "Das", "Dos", "A", "E"];
    let prepos = ["da", "do", "das", "dos", "a", "e"];

    for (var i = PreposM.length - 1; i >= 0; i--) {
        nomeTratado = nomeTratado.replace(RegExp("\\b" + PreposM[i].replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&') + "\\b", "g"), prepos[i]);
    }

    return nomeTratado;
}

export function getPorcentagemIndicador(name: string, total: number, value: any) {
    let porcentagem = 0;
    if (total && value) {
        porcentagem = value / total * 100;
        return porcentagem.toFixed(2) + "%";
    }

    return porcentagem + "%";
}