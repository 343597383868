import '../styles/footer.scss';

import fiocruzImg from '../assets/images/logo_fiocruz.svg';
import susImg from '../assets/images/SUS.svg';
import msGovernoImg from '../assets/images/ms_governo.svg';

export function Footer() {
    return (
        <footer>
            <div className="grid-sponsors">
                <img src={fiocruzImg} className="img-fluid" alt="Fiocruz" />
                <img src={susImg} className="img-fluid" alt="SUS" />
                <img src={msGovernoImg} className="img-fluid" alt="Ministério da Saúde - Governo Federal" />
            </div>
        </footer>
    )
}