import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';

import { Api } from '../services/api';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

import '../styles/selecionarubs.scss';

type Lista = {
    co_dim_unidade_saude_1: number;
    no_unidade_saude: string;
    nu_cnes: number;
}

type ResponseData = {
    data: Lista[];
}

export function SelecionarUbs() {
    let navigate = useNavigate();
    const [listaUbs, setListaUbs] = useState<Lista[]>([]);

    useEffect(() => {
        Api.get<ResponseData>('get-units').then(response => {
            const { data } = response.data;
            setListaUbs(data);
        })
    }, []);

    function handleToPainel() {
        navigate('/painelx');
    }

    const onChangeSelection = (e: any) => {
        navigate(`/painel/${e.target.value}`);
    };

    return (
        <div id="page-selecionar-ubs">
            <Header />

            <div className="contentWrapper">
                <div className="container-titulo mb-3">
                    <h2>Visualizar dados por:</h2>
                </div>

                <div className="container-escolher-ubs d-flex align-items-center">
                    <div className="container-municipio">
                        <button onClick={handleToPainel} type="button" className="btn btn-primary">Município</button>
                    </div>

                    <div className="ms-4 container-combo-ubs">
                        <Input
                            id="comboUbs"
                            name="comboUbs"
                            type="select"
                            className="combo-ubs"
                            onChange={onChangeSelection}
                        >
                            <option value="" hidden>Selecione</option>
                            {listaUbs.map(ubs => {
                                return (
                                    <option key={ubs.co_dim_unidade_saude_1} value={ubs.nu_cnes}>
                                        {ubs.no_unidade_saude}
                                    </option>
                                )
                            })}
                        </Input>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}