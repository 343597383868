
export const ageGroupParser = (data: any) => {
    if (data === undefined) return [];

    const ageGroups = data.ageGroups;
    const masculino = ageGroups.Masculino;
    const feminino = ageGroups.Feminino;
    if ( masculino === undefined) return {};
    const masculinoType = {
        type: 'Masculino',
        areaUrbana: {},
        areaRural: {}
    };
    const femininoType = {
        type: 'Feminino',
        areaUrbana: {},
        areaRural: {}
    };

    let ageGroupsKeys = [];

    if (masculino !== undefined) {
        ageGroupsKeys = Object.keys(masculino[0]);
        ageGroupsKeys.forEach((item: string) => {
            const newKey = item.replace('Faixa et\u00e1ria ', '')
            // @ts-ignore: Unreachable code error
            masculinoType.areaUrbana[newKey] = {
                value: masculino[0][item]['Urbano'],
                itemStyle: {
                    color: "#53efbf"
                }
            };
            // @ts-ignore: Unreachable code error
            masculinoType.areaRural[newKey] = { value: masculino[0][item]['Rural'], itemStyle: { color: '#1a8162' } };
        });
    }

    if (feminino !== undefined) {
        ageGroupsKeys = Object.keys(feminino[0]);
        ageGroupsKeys.forEach((item: string) => {
            const newKey = item.replace('Faixa et\u00e1ria ', '')
            // @ts-ignore: Unreachable code error
            femininoType.areaUrbana[newKey] = {
                value: feminino[0][item]['Urbano'],
                itemStyle: {
                    color: "#53efbf"
                }
            };
            // @ts-ignore: Unreachable code error
            femininoType.areaRural[newKey] = { value: feminino[0][item]['Rural'], itemStyle: { color: '#1a8162' } };
        });
    }

    return [masculinoType, femininoType];
}